import * as _superagent3 from "superagent";

var _superagent2 = "default" in _superagent3 ? _superagent3.default : _superagent3;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.execWarpscript = execWarpscript;

var _superagent = _interopRequireDefault(_superagent2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function execWarpscript(requestParams, options = {}) {
  const {
    retry = 0,
    timeout = 30000
  } = options;
  return (0, _superagent.default)("post", requestParams.url).retry(retry).timeout({
    response: timeout
  }).send(requestParams.body).then(r => {
    if (r.status !== 200) {
      const warp10error = "Warp10 Error: " + r.headers.get("X-Warp10-Error-Message");
      throw new Error(warp10error);
    }

    return JSON.parse(r.text);
  }).then(response => {
    return requestParams.responseHandler != null ? requestParams.responseHandler(response) : response;
  });
}

export default exports;
export const __esModule = exports.__esModule;
const _execWarpscript = exports.execWarpscript;
export { _execWarpscript as execWarpscript };